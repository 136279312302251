.code-gen {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.code-gen textarea {
  height: 160px;
}
.card:hover {
  transform: scale(1);
  border: 1 solid gray;
}

li {
  border: 1px solid #ccc;
}
