
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.memory-game{
  font-family: 'Righteous', sans-serif;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 50px;
  background-color: #c7cdd3;
}

.banner{
  margin-top: 25px;
}
.banner>img{
  max-width: 300px;
}

.memory-card{
  width: 120px;
  height: 120px;
  position: relative;
  cursor: pointer;
  -webkit-transform-style: preserve-3d;
  -webkit-transition: transform .5s;
 transform-style: preserve-3d;
  transition: transform .5s;
}

.card_back, .card_front{
  font-family: 'Righteous', sans-serif;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.45);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip{
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.card_front{
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background-color: #101c2c;

}

.card_back{
  color: #101c2c;
  background-color: #05c3ff;
  font-size: 30px;
  /* display: none; */

}
#gameOver{
  font-family: 'Righteous', sans-serif;

  position: absolute;
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  font-size: 50px;
  /* display: none; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top:0;
  left: 0;
}

#restart{
  padding: 20px;
  font-size: 20px;
  background-color: yellow;
  border: none;
  border-radius:10px;
  margin-top:10px;
}

#gameBoard{
  max-width: 700px;
  display: grid;
  grid-template: 1fr 1fr 1fr 1fr 1fr / 1fr 1fr 1fr 1fr;
  gap:20px
}

@media only screen and (max-width: 700px) {
  
  .game-board{
      max-width: 400px;
      grid-template: 1fr 1fr 1fr 1fr 1fr  / 1fr 1fr 1fr 1fr;
      gap: 20px;
    
  }
  .memory-card{
      width: 70px;
      height: 70px;}
  .icon
{
  width: 40px;
}
.card_front,.card_back{
border-radius:10px;
}
}