html,
body {
  min-height: 100vh;
  padding: 0;
  margin: 0;
}
body,
#root,
.bg {
  height: 100%;
}
#root {
  min-height: 100vh;
}
