.euro{
    font-family: Eurostile, sans-serif !important;
}
    
.hide{display: none !important;}


#popupStage{
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.50);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s linear;		

}
#closePopup{
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: Black;
}

.popupWindow{
    position: relative;
    width: 500px;
    height: 500px;
    padding: 20px;
    border-radius: 6px;
	background-color: #F9FBFC;
	box-shadow: -18px -18px 30px 0 #000, 18px 18px 30px 0 #000;
    transition: box-shadow 0.3s linear;		
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.popupHeader{
display: flex;
flex-direction: column;
align-items: center;

}
.popupLogo{
    margin-bottom: 40px;
}

.popupTitle{
    font-family: eurostile-condensed, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 2.0rem;
    margin-bottom: 5px;
}
.big{
    font-size: 2.5rem;
}
.txt-grad-dark-blue {
    background:  linear-gradient(187.04deg, #29678B 0%, #0E1C34 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position:relative; 
}	  
.txt-grad-dark-blue:after {
    background: none;
    content: attr(data-text);
    left: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    text-shadow:  5px 5px 30px rgba(247,181,0,0.25), 18px 18px 60px rgba(250,100,0,0.2);		
}
.popupText{
    font-size: 20px;
    text-align: center;
}
.countdown{
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.digit{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 800;
}
.digitValue{
    font-size: 30px;
}
.digitUnit{
    font-size: 18px;

}
.divider{
    height: 100%;
    width: 2px;
    background: linear-gradient(225deg, #FFDF00 0%, #FE4D1B 48.15%, #E70154 100%);

}
.buttons{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.pulse:hover{ 
    animation: pulse 0.9s infinite;
    animation-timing-function: linear;   
  }

  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1);}
    100% { transform: scale(1); }
    
  }
.zoom:hover{
transform: scale(1.1);
transition: all ease 500ms;
}
/* SS - Small Screen */
@media handheld and (max-width: 520px),
   screen and (max-device-width: 520px),
   screen and (max-width: 520px){
    .popupWindow{

    width: 300px;
    height: 500px;
    }
    .popupTitle{
  
        font-size: 1.5rem;
    
    
    }

    .popupText{
        font-size: 15px;
    }
    .buttons{
        height: 190px;
    flex-direction: column;
        justify-content: space-evenly;
    }
    .digitValue{
        font-size: 24px;
    }
    .digitUnit{
        font-size: 14px;
    
    }

    
   }