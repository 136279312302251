/* general settings */
* {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  color: #6d7278;
}

a:hover {
  text-decoration: none;
}

/* positions */
/* footer position */
body {
  min-height: 100vh;
}
/* body{min-height: 100vh; padding-bottom: 90px;} */

.footer {
  bottom: 0;
  width: 100%;
}

/* max-width */
.mw-xs {
  max-width: 330px;
}
.mw-sm {
  max-width: 450px;
}
.mw-md {
  max-width: 600px;
}
.mw-lg {
  max-width: 900px;
}

/* effects */
/* card */
.card:hover {
  transform: scale(1.05);
  transition: all ease 450ms;
}
.card {
  box-shadow: 9px 9px 15px #d1d9e6, 0px 3.70286px 3.70286px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding-top: 22px;
}
.list-group-item {
  border: none;
}
/* colors */
/* text color */
/* Solid */
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  color: #0e1c34;
  font-family: "Ubuntu", sans-serif;
}
.text-dark {
  color: #0e1c34 !important;
}
.text-light {
  color: #ffffff !important;
}
.text-muted {
  color: #b1afb9 !important;
}
.text-danger {
  color: #fe4d1b;
}
.text-alert {
  color: #f8af23;
}
/* Gradient */
.text-grad-orange {
  background: linear-gradient(225deg, #ffdf00 0%, #fe4d1b 48.15%, #e70154 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}
.text-grad-light-blue {
  background: linear-gradient(45deg, #1f95fd 0%, #4fe8e4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}
.text-grad-dark-blue {
  background: linear-gradient(225deg, #29678b 0%, #0e1c34 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}
.text-grad-fuchsia {
  background: linear-gradient(225deg, #e44ad4 0%, #b25ddf 43.75%, #1f95ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}
.text-grad-purple {
  background: linear-gradient(225deg, #c500e8 0%, #573592 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

/* background colors */
.bg-dark-blue {
  background: linear-gradient(183.73deg, #29678b 0%, #0e1c34 100%);
}
.bg-light-blue {
  background: linear-gradient(183.73deg, #29678b 0%, #0e1c34 100%);
}
.bg-gray {
  background-color: #f9fbfc;
}

/* buttons */
/* blue button */
.btn-blue {
  background: linear-gradient(19.98deg, #1f95fd 0%, #4fe8e4 100%);
  color: #0e1c34;
  border: none;
  font-weight: bold;
}
.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active {
  background: linear-gradient(
    50.94deg,
    rgba(31, 149, 253, 0.85) 0%,
    rgba(79, 232, 228, 0.85) 100%
  );
  color: #0e1c34;
  border: none;
}
/* orange button */
.btn-orange {
  background: linear-gradient(225deg, #ffdf00 0%, #fe4d1b 48.15%, #e70154 100%);
  border: none;
  color: #ffffff;
  font-weight: bold;
}
.btn-orange:hover,
.btn-orange:focus,
.btn-orange:active {
  background: linear-gradient(
    230.94deg,
    rgba(255, 223, 0, 0.85) 0%,
    rgba(254, 77, 27, 0.85) 48.15%,
    rgba(231, 1, 84, 0.85) 100%
  );
  border: none;
}

/* text */
.text-lg {
  font-size: 20px;
  line-height: 34px;
}
.text-md {
  font-size: 18px;
  line-height: 30px;
}
.text-sm {
  font-size: 14px;
  line-height: 20px;
}
h1,
.h1 {
  font-weight: 800;
  font-size: 32px;
  line-height: 42px;
}
h2,
.h2 {
  font-weight: 800;
  font-size: 26px;
  line-height: 34px;
}
h3,
.h3 {
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
}
h4,
.h4 {
  font-weight: 800;
  font-size: 17px;
  line-height: 25px;
}
h5,
.h5 {
  font-weight: 800;
  font-size: 16px;
  line-height: 25px;
}

/* Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  /* footer position */
  body {
    min-height: 100vh;
    /* padding-bottom: 56px; */
  }
  /* Text */
  .text-lg {
    font-size: 22px;
    line-height: 34px;
  }
  .text-md {
    font-size: 20px;
    line-height: 34px;
  }
  h1,
  .h1 {
    font-size: 36px;
    line-height: 52px;
  }
  h2,
  .h2 {
    font-size: 34px;
    line-height: 48px;
  }
  h3,
  .h3 {
    font-size: 20px;
    line-height: 34px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  /* Text */
  h1,
  .h1 {
    font-size: 64px;
    line-height: 70px;
  }
  h2,
  .h2 {
    font-size: 36px;
    line-height: 52px;
  }
  h3,
  .h3 {
    font-size: 22px;
    line-height: 36px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
